<template>
  <div id="app" class="container px-5 py-5">
    <nav class="nav">
      <h1 class="pb-2 border-bottom">LrcAPI 在线查询</h1>
    </nav>
    <form @submit.prevent="searchLyrics">
      <div class="input-group mb-3">
        <input v-model="title" type="text" class="form-control shadow" placeholder="标题" id="Title">
      </div>
      <div class="input-group mb-3">
        <input v-model="album" type="text" class="form-control shadow" placeholder="专辑" id="Album">
      </div>
      <div class="input-group mb-3">
        <input v-model="artist" type="text" class="form-control shadow" placeholder="艺术家" id="Artists">
      </div>
      <div class="input-group mb-3">
        <button type="submit" class="btn btn-outline-dark btn-lg shadow" id="SearchIt">
          搜索
        </button>
      </div>
    </form>
    <div v-if="loading" class="spinner-grow text-dark" role="status"></div>
    <div v-if="alertMessage" class="alert" :class="alertClass" role="alert">
      {{ alertMessage }}
    </div>
    <div v-if="lyricsList.length > 0" id="lyricsCarouselControls" class="carousel slide shadow" data-bs-ride="true">
      <div class="carousel-inner" id="lyricsShow">
        <div v-for="(lyric, index) in lyricsList" :key="index" :class="['carousel-item', { active: index === 0 }]">
          <div class="d-block w-100 text-center card">
            <div class="lrc-component g-body-tertiary rounded-3">
              <h3>{{ lyric.title }}</h3>
              <h6 class="text-secondary">{{ lyric.album }}</h6>
              <h6 class="text-primary-emphasis">{{ lyric.artist }}</h6>
              <pre>格式：TEXT/LRC</pre>
              <div id="Lyrics">
                <pre>{{ lyric.lyrics }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#lyricsCarouselControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#lyricsCarouselControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      title: '',
      album: '',
      artist: '',
      lyricsList: [],
      apiEndpoint: '/jsonapi',
      alertMessage: '',
      alertClass: '',
      loading: false
    };
  },
  methods: {
    async searchLyrics() {
      // 清空之前的警告信息
      this.alertMessage = '';
      this.alertClass = '';

      // 检查三个文本框是否均为空
      if (!this.title && !this.album && !this.artist) {
        this.alertMessage = '请输入至少一个搜索条件';
        this.alertClass = 'alert-warning';
        return;
      }

      // 清空之前的搜索结果
      this.lyricsList = [];

      // 设置加载状态
      this.loading = true;

      const queryParams = new URLSearchParams({
        title: this.title,
        album: this.album,
        artist: this.artist
      });

      try {
        const response = await fetch(`${this.apiEndpoint}?${queryParams}`);

        if (response.status === 404) {
          this.alertMessage = '没有查到相关内容';
          this.alertClass = 'alert-warning';
          return;
        }

        if (!response.ok) {
          this.alertMessage = '请求出错，请稍后再试';
          this.alertClass = 'alert-danger';
          return;
        }

        const data = await response.json();

        // 更新歌词列表
        this.lyricsList = data;
      } catch (error) {
        this.alertMessage = '请求出错，请稍后再试';
        this.alertClass = 'alert-danger';
      } finally {
        // 清除加载状态
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.lrc-component {
  height: 600px; /* 设置固定高度 */
  overflow-y: auto; /* 允许垂直滚动 */
  overflow-x: hidden; /* 隐藏水平滚动条 */
  padding: 15px; /* 可选：添加内边距 */
  word-wrap: break-word; /* 自动换行 */
  background-color: #ebebeb; /* 添加背景颜色 */
  margin: 5px; /* 添加四边边距 */
  scrollbar-width: none; /* Firefox:隐藏滚动条 */
}

/* Webkit-隐藏滚动条 */
.lrc-component::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
